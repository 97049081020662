function SchedulerEvents(options = {}) {
  const TIME_INTERVAL = 10;
  const MAX_INTERVAL_COUNT_IN_HOUR = Math.ceil(60 / TIME_INTERVAL);

  const schedulerControllerUrl = '/plugins/shows/scheduler-controller.jsp';
  const calendarControllerUrl = '/plugins/shows/calendar-controller.jsp';
  const appointmentControllerUrl = '/plugins/shows/appointment-controller.jsp';
  const goldShowsControllerUrl = Config.exec + '/postback/gold_show.jsp';

  const dateFormat = options.dateFormat || 'mm/dd/yy';
  const datepickerId = '#datepicker-scheduler';
  const datetimeId = '#event-calendar-scheduler';
  const isSchedulerPage = $('input[name=isSchedulerPage]').val() === 'true';

  const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

  let availableDates = {};
  let availableTimeArray = [];
  let schedulerBlockedDates = {};
  let schedulerBlockedTimeArray = [];

  async function initDatetimepicker(performerId) {
    const requests = createRequests(performerId);

    $.when(...requests).done((...responses) => {
      const [calendarRes = [], schedulerRes = [], appointmentRes = [], goldShowsRes = []] = responses.map(parseResponse);

      availableDates = parseCalendarTime(calendarRes, true);
      const eventsBlockedDates = parseEventsTime([...appointmentRes, ...goldShowsRes]);
      schedulerBlockedDates = parseCalendarTime(schedulerRes);

      renderDatepicker($(datepickerId), eventsBlockedDates);
    });
  }

  function createRequests(performerId) {
    const calendarReq = {cmd: 'get-calendar', performerId};
    const schedulerReq = {cmd: 'get-timelock', performerId};
    const appointmentReq = {cmd: 'get-events', performerId};
    const goldShowsReq = {command: 'get-approved-shows-by-performer-id', performerId};

    const requests = [
      $.post(calendarControllerUrl, calendarReq, null, 'json'),
      $.post(schedulerControllerUrl, schedulerReq, null, 'json')
    ];

    if (!isSchedulerPage) {
      requests.push(
        $.post(appointmentControllerUrl, appointmentReq, null, 'json'),
        $.get(goldShowsControllerUrl, goldShowsReq, null, 'json')
      );
    }

    return requests;
  }

  function parseResponse(response) {
    if (!response?.[0]) return [];

    const { message, code, res } = response[0];

    return (code && code !== 'ERR') || res ? JSON.parse(message) || [] : [];
  }

  function parseCalendarTime(calendarArray, isCalendar) {
    if ((!calendarArray || calendarArray.length === 0) && !isCalendar) return {};
    const model = new Map();

    if (calendarArray.length === 0 && isCalendar) {
      // Initializes 24 hours for each day if the calendar is empty
      daysOfWeek.forEach(day => {
        for (let i = 0; i < 24; i++) {
          const newTime = {
            startHour: { hour: i, minute: 0 },
            endHour: { hour: i, minute: 0 }
          };
          addTimeToModel(model, day, newTime);
        }
      });
    } else {
      calendarArray.forEach(item => {
        if (!item) return;

        const newTime = {
          startHour: item['startLocalTime'],
          endHour: item['endLocalTime']
        };

        if ('dayOfWeek' in item) {
          addTimeToModel(model, item.dayOfWeek, newTime);
        } else if ('localDate' in item) {
          const {year, month, day} = item['localDate'];
          const dateTimestamp = new Date(year, month - 1, day).getTime();
          newTime.id = item.id;

          addTimeToModel(model, dateTimestamp, newTime);
        }
      });
    }

    return Object.fromEntries(model);
  }

  function parseEventsTime(events) {
    if (!events || events.length === 0) return {};
    const model = new Map();

    events.forEach(event => {
      if (!event) return;

      let startTimestamp;
      let endTimestamp;

      if (event.type === 'APPOINTMENT') {
        startTimestamp = event.startTime.seconds * 1000;
        endTimestamp = event.endTime.seconds * 1000;
      } else {
        const {year, month, day} = event.startDate.date;
        const {hour, minute, second} = event.startDate.time;

        startTimestamp = new Date(year, month - 1, day, hour, minute, second).getTime();
        endTimestamp = startTimestamp + event.duration * 60 * 1000;
      }

      const startDayTimestamp = new Date(startTimestamp).setHours(0, 0, 0, 0);
      const endDayTimestamp = startDayTimestamp + 86400000;

      const newTime = {
        startTime: startTimestamp,
        endTime: endTimestamp
      };

      addTimeToModel(model, startDayTimestamp, newTime);
      if (new Date(startTimestamp).getDate() !== new Date(endTimestamp).getDate()) {
        addTimeToModel(model, endDayTimestamp, newTime);
      }
    });

    return Object.fromEntries(model);
  }

  function addTimeToModel(model, date, newTime) {
    const timesForDay = model.get(date) || [];
    timesForDay.push(newTime);
    model.set(date, timesForDay);
  }

  function renderDatepicker(datepicker, eventsBlockedDates = {}) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const availableDaysOfWeek = Object.keys(availableDates);

    if (datepicker.hasClass('ui-datepicker')) datepicker.datepicker('destroy');

    datepicker.datepicker({
      inline: true,
      altField: datetimeId,
      altFormat: dateFormat,
      dateFormat: dateFormat,
      defaultDate: today,
      minDate: today,
      maxDate: '+6m',
      beforeShowDay: function (thisDate) {
        const schedulerBlockedDates = getSchedulerBlockedDates();
        const thisDayOfWeek = daysOfWeek[thisDate.getDay()];
        const thisDayTimestamp = new Date(thisDate).getTime();
        const isThisDayAvailable = availableDaysOfWeek.includes(thisDayOfWeek) && availableDates[thisDayOfWeek].length > 0;
        let thisDayClass = thisDate.getTime() < today.getTime() ? 'ui-state-past-day' : '';

        if (isThisDayAvailable && thisDayTimestamp in schedulerBlockedDates && schedulerBlockedDates[thisDayTimestamp].length > 0) {
          const isThisDayBlockedByScheduler = availableDates[thisDayOfWeek].every(thisDayTime =>
            schedulerBlockedDates[thisDayTimestamp].some(schedulerBlockTime =>
              schedulerBlockTime.startHour.hour === thisDayTime.startHour.hour
            )
          );

          if (isThisDayBlockedByScheduler) thisDayClass += ' ui-state-date-disabled';
        }

        return [isThisDayAvailable, thisDayClass];
      },
      onSelect: function () {
        const schedulerBlockedDates = getSchedulerBlockedDates();
        const thisDate = datepicker.datepicker('getDate');
        const thisDayOfWeek = daysOfWeek[thisDate.getDay()];
        const thisDayTimestamp = new Date(thisDate).getTime();

        const eventsBlockedTimeArray = eventsBlockedDates[thisDayTimestamp] || [];

        availableTimeArray = availableDates[thisDayOfWeek] || [];
        availableTimeArray = availableTimeArray.sort((a, b) => a.startHour.hour - b.startHour.hour);
        schedulerBlockedTimeArray = schedulerBlockedDates[thisDayTimestamp] || [];
        schedulerBlockedTimeArray = schedulerBlockedTimeArray.sort((a, b) => a.startTime - b.startTime);

        renderTimepicker(thisDate, eventsBlockedTimeArray);
      }
    });

    $(datepickerId + ' .ui-datepicker-current-day').click();
  }

  // Don't access schedulerBlockedDates directly.
  function getSchedulerBlockedDates() {
    return schedulerBlockedDates;
  }

  function renderTimepicker(thisDate, eventsBlockedTimeArray) {
    const availableTimeRanges = getAvailableTimeRangesForSelectedDay(thisDate, eventsBlockedTimeArray);
    if (!availableTimeRanges.length) return;
    availableTimeRanges.sort((a, b) => a.startTime[0] - b.startTime[0]);

    const $timepicker = $('#time-picker-scheduler');
    const dayParts = getDayParts();

    $timepicker.find('.day-part').children().not('.time-title').remove();

    for (const part in dayParts) {
      dayParts[part].forEach(dataTime => {
        const isChecked = dataTime.timeLockId !== -1;
        $timepicker.find('#part-'+part).append(
          isSchedulerPage
            ? `<li class="time js-time-add ${isChecked ? ' checked' : ''}" data-hour="${dataTime.startHour}" ${isChecked ? 'data-id="'+dataTime.timeLockId+'"' : ''}>${dataTime.formattedInterval}</li>`
            : `<li class="time">${dataTime.formattedInterval}</li>`
        );
      });
    }

    function getDayParts() {
      const dayParts = {morning: [], afternoon: [], evening: [], night: []};
      const blockedTimes = schedulerBlockedTimeArray.reduce((acc, {startHour, id}) => {
        acc[startHour.hour] = id;
        return acc;
      }, {});

      availableTimeRanges.forEach(availableRange => {
        const [startHour, startMinute] = availableRange.startTime;
        const [endHour, endMinute] = availableRange.endTime;
        const timeLockId = blockedTimes[startHour] || -1;

        const dataTime = {
          formattedInterval: `${formatTime(startHour, startMinute)} - ${formatTime(endHour, endMinute)}`,
          startHour: startHour,
          timeLockId: timeLockId
        };

        if (startHour >= 5 && startHour < 11) dayParts.morning.push(dataTime);
        else if (startHour >= 11 && startHour < 17) dayParts.afternoon.push(dataTime);
        else if (startHour >= 17 && startHour < 23) dayParts.evening.push(dataTime);
        else dayParts.night.push(dataTime);
      });

      return dayParts;
    }

    function formatTime(hour, minute) {
      if (hour === 24) hour = 0
      const formatTimeUnit = timeUnit => timeUnit < 10 ? '0' + timeUnit : timeUnit;

      return `${formatTimeUnit(hour)}:${formatTimeUnit(minute)}`;
    }
  }

  function getAvailableTimeRangesForSelectedDay(thisDate, eventsBlockedTimeArray) {
    if (!availableTimeArray?.length) return [];

    thisDate = new Date(thisDate);
    eventsBlockedTimeArray = eventsBlockedTimeArray.sort((a, b) => a.startTime - b.startTime);

    const result = [];

    for (const hour of availableTimeArray) {
      let startHour = hour.startHour.hour;
      let startMinute = hour.startHour.minute;
      let endHour = hour.endHour.hour;
      let endMinute = hour.endHour.minute;

      if (startHour === endHour && startMinute === endMinute) {
        endHour++;
      }

      if (!isSchedulerPage) {
        const isHourBlockedByScheduler = schedulerBlockedTimeArray.some(scheduledTime => scheduledTime.startHour.hour === startHour);
        if (isHourBlockedByScheduler) continue;
      }

      const availableIntervalsInHour = getAvailableIntervalsInHour(startHour, thisDate, eventsBlockedTimeArray);
      if (availableIntervalsInHour.length > 0 && availableIntervalsInHour.length < MAX_INTERVAL_COUNT_IN_HOUR) {
        const mergedIntervals = [availableIntervalsInHour[0]];

        for (const interval of availableIntervalsInHour) {
          const lastMergedInterval = mergedIntervals[mergedIntervals.length - 1];

          if (interval[0] <= lastMergedInterval[1]) {
            lastMergedInterval[1] = Math.max(lastMergedInterval[1], interval[1]);
          } else {
            mergedIntervals.push(interval);
          }
        }

        for (const mergedInterval of mergedIntervals) {
          startMinute = mergedInterval[0];
          endMinute = mergedInterval[1];

          if (endMinute < 60) endHour = endHour - 1;
          if (endMinute >= 60) endMinute = 0;

          pushResult();
        }
      } else if (availableIntervalsInHour.length === MAX_INTERVAL_COUNT_IN_HOUR) {
        pushResult();
      }

      function pushResult() {
        result.push({
          startTime: [startHour, startMinute],
          endTime: [endHour, endMinute]
        });
      }
    }

    return result;
  }

  function getAvailableIntervalsInHour(startHour, thisDate, eventsBlockedTimeArray) {
    const availableIntervalsInHour = [];
    const hourStartTimestamp = new Date(thisDate).setHours(startHour, 0, 0, 0);

    for (let i = 0; i < 60; i += TIME_INTERVAL) {
      const startIntervalTimestamp = hourStartTimestamp + i * 60 * 1000;
      const endIntervalTimestamp = startIntervalTimestamp + TIME_INTERVAL * 60 * 1000;

      const intervalIsAvailable = !eventsBlockedTimeArray.some(eventTime => {
        const startEventTimestamp = eventTime.startTime;
        const endEventTimestamp = eventTime.endTime + 60 * 1000;

        return (
          (startEventTimestamp >= startIntervalTimestamp && startEventTimestamp < endIntervalTimestamp) ||
          (endEventTimestamp >= startIntervalTimestamp && endEventTimestamp < endIntervalTimestamp) ||
          (startEventTimestamp <= startIntervalTimestamp && endEventTimestamp >= startIntervalTimestamp)
        );
      });

      if (intervalIsAvailable) availableIntervalsInHour.push([i, i + TIME_INTERVAL])
    }

    return availableIntervalsInHour;
  }

  initDatetimepicker($('input[name=performerId]').val() || 0).then(() => {
    const $scheduler = $('.schedule-calendar .scheduler');

    $scheduler.on("click", ".js-time-add", toggleDateAvailability);
    $scheduler.on("click", ".js-part-add", toggleDateAvailability);
    $scheduler.on("mousedown", "td[data-event='click'] a.ui-state-default", handleMouseDown);

    let lastMousedownTime = 0;
    function handleMouseDown(e) {
      e.stopPropagation();
      e.preventDefault();
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastMousedownTime;

      if (timeDiff < 300) {
        toggleDateAvailability(e);
      } else {
        lastMousedownTime = currentTime;
      }
    }
  });

  async function toggleDateAvailability(e) {
    e.preventDefault();
    const $dataEl = $(e.target);

    if ($dataEl.hasClass("inProgress")) return;
    $dataEl.addClass("inProgress");

    const dateString = $('input[name=datetime-scheduler]').val();
    const [initMonth, initDay, initYear] = dateString?.split('/').map(Number) || [];
    if (![initMonth, initDay, initYear].every(Number.isFinite)) {
      $dataEl.removeClass('inProgress');
      return;
    }

    const ids = [];
    const hours = [];
    let data;

    const dataPart = $dataEl.data('part');
    const dataHour = $dataEl.data('hour');

    if (dataHour >= 0) {
      const foundTime = schedulerBlockedTimeArray.find(item => item.startHour.hour === dataHour);
      foundTime ? ids.push(foundTime.id) : hours.push(dataHour);
    } else {
      const partHours = getHoursByPart(dataPart || 'all');
      partHours.forEach(hour => {
        const found = schedulerBlockedTimeArray.find(item => item.startHour.hour === hour);
        found ? ids.push(found.id) : hours.push(hour);
      });
    }

    if (hours.length > 0) {
      data = {
        cmd: 'add-timelock',
        day: initDay,
        month: initMonth,
        year: initYear,
        hours: JSON.stringify(hours),
      };
    } else if (ids.length > 0) {
      data = {
        cmd: 'remove-timelock',
        ids: JSON.stringify(ids),
      };
    } else {
      $dataEl.removeClass('inProgress');
      return;
    }

    $.ajax({
      url: '/plugins/shows/scheduler-controller.jsp',
      method: 'POST',
      cache: false,
      data: data,
      success: function (response) {
        if (response.code === 'OK') {
          $.post(
            schedulerControllerUrl,
            {cmd: 'get-timelock', performerId: $('input[name=performerId]').val() || 0},
            null, 'json'
          ).done((schedulerRes) => {
            schedulerRes = parseResponse([schedulerRes]);
            schedulerBlockedDates = parseCalendarTime(schedulerRes);

            renderDatepicker($(datepickerId));
            $(datepickerId + ' .ui-state-active').click();
          });
        }
      },
      error: () => alert('error!')
    }).always(()=> $dataEl.removeClass("inProgress"));


    function getHoursByPart(part) {
      const partsMap = {
        morning: [5, 6, 7, 8, 9, 10],
        afternoon: [11, 12, 13, 14, 15, 16],
        evening: [17, 18, 19, 20, 21, 22],
        night: [23, 0, 1, 2, 3, 4],
        all: Array.from({ length: 24 }, (_, i) => i),
      };
      return partsMap[part] || [];
    };
  }
}